import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import News from "./components/news.component";
import NewsList from "./components/news-list.component";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="app">
          <header>
            <div className="logo">
              <img src="./logo.png" alt="SNRT NEWS" />
            </div>
            <h1 class="no-print">API</h1>
          </header>
          <div className="main-container">
            <Switch>
              <Route exact path={["/", "/news"]} component={NewsList} />
              <Route path="/news/:id" component={News} />
            </Switch>
          </div>
          <footer>
            @ 2021 SNRT News
          </footer>
        </div>
      </Router>
    );
  }
}

export default App;
