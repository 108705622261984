import React, { Component } from "react";
import NewsDataService from "../services/news.service";
import parse from 'html-react-parser';
import Moment from 'moment';
import DownloadLink from "react-download-link";

export default class News extends Component {
  constructor(props) {
    super(props);
    this.getNews = this.getNews.bind(this);

    this.state = {
      currentNews: {
        id: null,
        title: "",
        description: "",
        published: false
      },
      message: ""
    };
  }

  componentDidMount() {
    this.getNews(this.props.match.params.id);
  }

  getNews(id) {
    let type = new URLSearchParams(this.props.location.search).get("type")
    NewsDataService.get(type+"/"+id)
      .then(response => {
        this.setState({
          currentNews: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }
  display() {
    window.print();
  }
  download() {
    const { currentNews } = this.state;
    var anchor = document.createElement('a');
    anchor.href = currentNews.attributes?.video_link;
    anchor.download = currentNews.attributes?.video_link;
    document.body.appendChild(anchor);
    anchor.click();
  }

  

  render() {
    const { currentNews } = this.state;

    return (
      <div>
        <a className="no-print backLink" href="javascript:void()" onClick={this.props.history.goBack}>&lt;&nbsp;Retour</a>
        {currentNews ? (
          <div  className={
            "edit-form list-group-item "
            +
            (currentNews.attributes?.langue === 'AR' ? "rightText" : "")
          } 
           dir="auto">
            
            <h4 className='title'>{currentNews.attributes?.title !== undefined ? parse(currentNews.attributes?.title) : currentNews.attributes?.short_desc !== undefined ? parse(currentNews.attributes?.short_desc):'News'}
            </h4>
            <span className="source itemfooter">{currentNews.type}&nbsp;{Moment(currentNews?.attributes?.first_created).format('DD/MM/YYYY hh:mm')}</span>
            <br/> <br/>
            <div class="content">
              {currentNews.attributes?.long_desc !== undefined && parse(currentNews.attributes?.long_desc)}
              {currentNews.attributes?.news !== undefined && 
                parse(currentNews.attributes?.news) 
              }

{currentNews.attributes?.news !== undefined ?
                <button className="no-print print-btn" onClick={this.display}>Imprimer</button> : ''}
              {currentNews.attributes?.video_link !== undefined &&
                  <div className="video">
                    <video width="320" height="240" controls>
                    <source src={currentNews.attributes?.video_link} type="video/mp4"/>
                    Your browser does not support the video tag.
                    </video><br/>
                    <DownloadLink
                        label="Télécharger"
                        filename={currentNews.attributes?.video_link}
                        exportFile={() => "Client side cache data here…"}
                    />
                    {/* <button className="no-print print-btn" onClick={this.download}>Télécharger</button>  */}
                  </div>
                  
                  
                }

            </div>
          </div>
        ) : (
          <div>
            <br />
            <p>Please select News...</p>
          </div>
        )}
      </div>
    );
  }
}