import React, { Component } from "react";
import NewsDataService from "../services/news.service";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import Moment from 'moment';
import parse from 'html-react-parser';
import CheckBox from './checkBox';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class NewsList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveNewsList = this.retrieveNewsList.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    

    this.state = {
      newsList: [],
      currentNews: null,
      currentIndex: -1,
      searchTitle: "",
      dateFrom:"",
      dateTo:"",
      page: 1,
      count: 0,
      pageSize: 10,
      sources: [
        { id: 1, value: "map", label: "Map", isChecked: true },
        { id: 2, value: "sntv", label: "SNTV", isChecked: true },
        { id: 3, value: "reuters", label: "Reuters", isChecked: true },
        { id: 4, value: "eurovision", label: "EuroVision", isChecked: true }
      ],
      interval:null
    };

    this.pageSizes = [10, 15, 30, 60, 100];
  }

  componentDidMount() {
    this.refreshList();
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  
  getRequestParams(searchTitle, page, pageSize,sources,dateFrom,dateTo) {
    let params = {};
    let activeSource = 0;
    sources.forEach(async (source) => {
      if(source.isChecked){
        activeSource++;
      }
      
    }); 

    params["sort"] = "first_created";

    let filter = [];

    if (searchTitle) {
      filter.push({"name":"news","op":"like","val":"%" + searchTitle + "%"});
    }
    if (dateFrom) {
      filter.push({"name":"first_created","op":"ge","val":dateFrom });
    }
    if (dateTo) {
      filter.push({"name":"first_created","op":"le","val":dateTo});
    }
    params["filter"] = JSON.stringify(filter);
    if (page) {
      params["page[number]"] = page;
    }

    if (pageSize) {
      params["page[size]"] = Math.ceil(pageSize/activeSource) ;
    }

    return params;
  }

  async retrieveNewsList() {
    const { searchTitle, page, pageSize,sources,dateFrom,dateTo } = this.state;
    const params = this.getRequestParams(searchTitle, page, pageSize,sources,dateFrom,dateTo);

    NewsDataService.getAll(params,sources)
      .then((responses) => {
        console.log(responses);
        let datas = [];
        let count = 0;
        responses.forEach(response =>{
          if(response){
            const { data, meta } = response;
            datas.push(...data);
            count += meta.count;
          }
         
        })
        console.log(count);
        this.setState({
          newsList: datas,
          count: Math.ceil(count/ pageSize),
        });
        console.log(datas);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  refreshList() {
    const {page,interval } = this.state;
    console.log('page',page);
    console.log('interval',interval);
    if(page === 1 && interval === null){
      console.log('page 1');
      let interval = setInterval(() => {
        this.refreshList();
      }, 300000);
      this.setState({
        interval: interval,
      });
    }else if(page > 1 && interval !== null){
      clearInterval(interval);
      this.setState({
        interval: null,
      });
      console.log('clear');
    }
    this.retrieveNewsList();
    this.setState({
      currentNews: null,
      currentIndex: -1,
    });
  }

  setActiveNews(news, index) {
    this.setState({
      currentNews: news,
      currentIndex: index,
    });
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveNewsList();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1
      },
      () => {
        this.retrieveNewsList();
      }
    );
  }

  handleCheckChieldElement = (event) => {
    let sources = this.state.sources
    sources.forEach(source => {
      if (source.value === event.target.value)
        source.isChecked = event.target.checked
    })
    this.setState({ sources: sources })
  }

  setStartDate = (date) => {
    this.setState({ dateFrom: date })
  }
  setEndDate = (date) => {
    this.setState({ dateTo: date })
  }

  render() {
    const {
      searchTitle,
      newsList,
      currentNews,
      currentIndex,
      page,
      count,
      pageSize,
      dateFrom,
      dateTo
    } = this.state;

    return (
      <div className="list">
        <div className="filterBlock">
          <div className="source-block filter">
            <span className="title">Source</span>

            <ul className="sourcefilter">
              {
                this.state.sources.map((source) => {
                  return (<CheckBox handleCheckChieldElement={this.handleCheckChieldElement} {...source} />)
                })
              }
            </ul>

          </div>
          <div className="date-block filter">
            <span className="title">Date</span>
            <div className="dateBlock"><span>De</span> <div className="datepicker"><DatePicker selected={dateFrom} 
                  onChange={date => this.setStartDate(date)} 
                  selectsStart
                  startDate={dateFrom}
                  endDate={dateTo}
                  /></div></div>
            <div className="dateBlock"><span>A</span> <div className="datepicker"><DatePicker selected={dateTo} 
            onChange={date => this.setEndDate(date)} 
            selectsEnd
            startDate={dateFrom}
            endDate={dateTo}
            minDate={dateFrom}
            /></div></div>
          </div>
        </div>
        <div className="row">
          <div className="col align-self-center">
            <div className="input-group mb-3 searchBlock">
              <input
                type="text"
                className="form-control searchInput"
                placeholder="Recherche..."
                value={searchTitle}
                onChange={this.onChangeSearchTitle}
              />
              <div className="input-group-append">
                <button
                  className="btn searchbtn"
                  type="button"
                  onClick={this.retrieveNewsList}
                >
                  Recherche
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="mt-3 paginationList">
              {"News par Page: "}
              <select onChange={this.handlePageSizeChange} value={pageSize}>
                {this.pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>


            </div>

            <ul className="list-group">
              {newsList &&
                newsList.map((news, index) => (
                  
                  <li 
                    className={
                      "list-group-item " +
                      (index === currentIndex ? "active" : "")
                      +
                      (news.attributes.langue === 'AR' ? "rightText" : "")
                    }
                    key={index}
                  >
                    <Link to={"/news/" + news.id + "?type=" + news.type} className="title">
                      {news.attributes.title !== undefined ? parse(news.attributes.title) : news.attributes.short_desc !== undefined ? parse(news.attributes.short_desc):'News'}
                      
                    </Link>
                    <div className="content">
                      {news.attributes.title === undefined ? '': news.attributes.short_desc !== undefined ? parse(news.attributes.short_desc): ''}
                    </div>
                    {news.attributes.video_link !== undefined &&
                      <div className="video">
                        <video width="320" height="240" controls>
                          <source src={news.attributes.video_link} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                      </div>

                    }
                    <div className="itemfooter">
                      <span className="source">{news.type}</span>&nbsp;{Moment(news.attributes.first_created).format('DD/MM/YYYY hh:mm')}
                    </div>
                  </li>
                ))}
            </ul>
            <div className="align-self-center">
              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                showFirstButton showLastButton
                onChange={this.handlePageChange}
              />
            </div>

          </div>
        </div>
      </div>
    );
  }
}