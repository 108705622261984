import React from 'react'

export const CheckBox = props => {
    return (
      <li>
        {props.label}<br/>
      <label class="checkbox-container">
        <input key={props.id} onClick={props.handleCheckChieldElement} type="checkbox" checked={props.isChecked} value={props.value} /> 
        <span class="checkmark"></span>
      </label>
      </li>

    )
}

export default CheckBox