

class NewsDataService {

    endPoint = 'http://c21535c03bd1.ngrok.io';
    
  
    async getAll(params,sources) {
        
        
        // let responses = [];
        let promises = [];
        sources.forEach(async (source) => {
          if(source.isChecked){
            switch (source.value) {
              case 'sntv':
              case 'reuters':
              case 'eurovision':
                params["filter"] = params["filter"].replace('"name":"news"','"name":"short_desc"');
                console.log(params["filter"]);
              break;
            
              default:
                params["filter"] = params["filter"].replace('"name":"short_desc"','"name":"news"');
                console.log(params["filter"]);
                break;
            }
            let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

            let url = this.endPoint+'/'+source.value+'?'+query;
            promises.push(this.getData(url,params));
          }
          
        }); 
             
        let responses = Promise.all(promises).then((values) => {
          // console.log(values);
          return values;
        });
        
        return responses;
    }

    async getData(url){
      let response;

      let headers = new Headers();
    
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
        

      response = await fetch(url,{
        mode: 'cors',
        cache: 'no-cache',
        method: 'GET',
        headers: headers
      })
        .then(response => 
          {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          }
        )
        .then(response =>{
          return response;
        })
        .catch(error => {
          console.error(error);
        }
      );
      return response;
    }
  
    async get(link) {
      let headers = new Headers();
    
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
      let url = this.endPoint+"/"+link;
      let response = await fetch(url,{
        mode: 'cors',
        cache: 'no-cache',
        method: 'GET',
        headers: headers
      })
        .then(response => 
          {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          }
        )
        .then(response =>{
          return response;
        })
        .catch(error => {
          console.error(error);
        }
      );

      return response;
    }
  }
  
  export default new NewsDataService();